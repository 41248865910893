const breakPoints = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
};

export const defaultTheme = (() => {
    const colors = {
      menuBackground: '#F2F7FF',
      menuActive: '#6133DE',
      button: '#6133DE',
      green: '#27AE60',
      red: '#EB5757',
      inputBorder: '#6664',
      gray: '#777',
      error: '#ee4433',
    }
    return { colors, breakPoints }
  })()
  
  export const darkTheme = (() => {
    const colors = {
      menuBackground: '#F2F7FF',
      menuActive: '#6133DE',
      button: '#6133DE',
      green: '#27AE60',
      red: '#EB5757',
      inputBorder: '#6664',
      gray: '#777',
      error: '#ee4433',
    }
    return { colors, breakPoints }
  })()