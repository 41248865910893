import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TwitterIcon } from "images/twitter.svg";
import { ReactComponent as TelegramIcon } from "images/telegram.svg";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
import { ReactComponent as MediumIcon } from "images/medium.svg";
import MainBottomBackground from "images/bottom-background.png";
import styled from "styled-components";
import { Colors } from "config/constants/Colors";

const BottomBar = () => {
  const navigate = useNavigate();
  const comingSoonNames = [
    "Retail",
    "DeFi Innovators",
    "Blockchains",
    "Wallets",
    "Exchanges",
    "Shopping",
  ];
  // const linkNames = [{
  //   label: 'Company',
  //   link: ''
  // }, {
  //   label: 'Team & Advisors',
  //   link: ''
  // }, {
  //   label: 'Contact',
  //   link: ''
  // }];
  const linkNames = [
    {
      label: "Contact",
      link: "",
    },
  ];

  return (
    <BottomPage style={{ position: "relative" }}>
      <div style={{ background: "white" }}>
        <Container>
          <FlexColumnSocial>
            <FlexStart style={{ gap: "12px", justifyContent: "center" }}>
              <Text bold="600" size="24px" color={Colors.black}>
                Maaly
              </Text>
            </FlexStart>
            {/* <Text
              size="14px"
              bold="600"
              color={Colors.textGray1}
              style={{ textAlign: "left" }}
            >
              BE PART OF OUR COMMUNITY
            </Text>
            <FlexRowStart>
              <a
                href="https://twitter.com/SphynxLabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <TwitterIcon width={18} height={18} />
                </IconWrapper>
              </a>
              <a
                href="https://t.me/sphynxlabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <TelegramIcon width={18} height={18} />
                </IconWrapper>
              </a>
              <a
                href="https://www.instagram.com/sphynxlabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <InstagramIcon width={18} height={18} />
                </IconWrapper>
              </a>
              <a
                href="https://sphynxlabs.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWrapper>
                  <MediumIcon width={18} height={18} />
                </IconWrapper>
              </a>
            </FlexRowStart> */}
          </FlexColumnSocial>
          <Text
            size="16px"
            bold="400"
            color={Colors.textGray}
            style={{
              paddingTop: "40px",
              marginTop: "40px",
              width: "100%",
              textAlign: "center",
              borderTop: "1px solid #ddd",
            }}
          >
            © All rights reserved to Maaly
          </Text>
        </Container>
      </div>
      <BottomBackground />
    </BottomPage>
  );
};

export default BottomBar;

const BottomPage = styled.div`
  margin-top: 40px;
  position: relative;
  border-top: 1px solid #aaa;
`;
const BottomBackground = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  background: ${Colors.black};
  background-position-y: 10px;
  background-image: url(${MainBottomBackground});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: auto;
  object-position: center;
`;
const Container = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    padding: 40px 0px;
    width: 100%;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;
const FlexColumnSocial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;
const Text = styled.p`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? "0.8" : "1")};
  }
`;
const FlexRowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const IconWrapper = styled.div`
  padding: 9px;
  background: ${Colors.socialBgColor};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
`;
const FlexStart = styled.div`
  display: flex;
  align-items: center;
`;
