import { createReducer } from '@reduxjs/toolkit'
import {
    setUserInfo
  } from './actions'

  const initialState = {
    info: {},
  }

  export default createReducer(initialState, (builder) =>
  builder
    .addCase(setUserInfo, (state, { payload }) => {
      if (payload.userInfo) {
        state.info = payload.userInfo
      } else {
        state.info = {}
      }
    })
)