import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { setLanguage } from "state/global/actions"
import { isEmpty } from "utils"
import { useAppDispatch } from "state"

export default function LanguageSelector() {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const { language } = useSelector((state) => state.global)
  const global = useSelector((state) => state.global)

  const onChangeLanguage = (event) => {
    if (!isEmpty(i18n)) {
      i18n
        ?.changeLanguage(event.target.value)
        .then(() => dispatch(setLanguage(event.target.value)))
        .catch((err) => console.log("i18n Error : ", err))
    } else {
      dispatch(setLanguage(event.target.value))
    }
  }

  return (
    <LanguageSelect onChange={onChangeLanguage}>
      <Option value="id">Indonesian</Option>
      <Option value="en">English</Option>
    </LanguageSelect>
  )
}

const LanguageSelect = styled.select`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  &:hover {
    border-color: #895ff2;
  }
  &:focus-visible {
    outline: none;
    border: 1px solid #895ff2;
  }
`

const Option = styled.option`
  padding: 12px;
`
