import { createReducer } from "@reduxjs/toolkit"
import { setLanguage } from "./actions"

const initialState = {
  language: "id",
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setLanguage, (state, { payload: language }) => {
    state.language = language
  })
)
