import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleMenu as _toggleMenu,
  toggleTheme as _toggleTheme,
  setCookie as _setCookie
} from "./actions";

export function useMenuToggle() {
  const dispatch = useDispatch();
  const menuToggled = useSelector((state) => state.application.menuToggled);

  const toggleMenu = (open) => dispatch(_toggleMenu(open));

  return { menuToggled, toggleMenu };
}

export function useCookie() {
  const dispatch = useDispatch();
  const cookie = useSelector((state) => state.application.cookie);

  const setupCookie = (cookie) => dispatch(_setCookie({ cookie }));

  return { cookie, setupCookie };
}

export function useDefaultThemeChange() {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.application.theme);
  const toggleDefaultTheme = useCallback(() => {
    dispatch(_toggleTheme());
  }, [dispatch]);

  return [currentTheme, toggleDefaultTheme];
}
