import React, { useEffect, useState } from "react";

import styled, { keyframes } from "styled-components";
import { Colors } from "config/constants/Colors";
import BottomBar from "layouts/Bottombar";
import { useCookie } from "state/application/hooks";
import { ReactComponent as GlobeIcon } from "images/Globe.svg";
import { ReactComponent as MailIcon } from "images/Mail.svg";
import { ReactComponent as UsersIcon } from "images/Users.svg";
import useIsMobile, { useScreenWidth } from "hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import Modal from "components/Modal";
import LanguageSelector from "components/LanguageSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const { cookie } = useCookie();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [showNoWalletModal, setShowNoWalletModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const screenWidth = useScreenWidth();

  return (
    <>
      <HomePage>
        <LanguageWrapper>
          <LanguageSelector />
        </LanguageWrapper>
        {showNoWalletModal && (
          <Modal
            title={t("How it works if you do not have a Crypto Wallet")}
            onDismiss={() => setShowNoWalletModal(false)}
          >
            <div style={{ lineHeight: "2rem", fontWeight: 400 }}>
              <p>
                1.{" "}
                {t(
                  "Download a non-custodial wallet (I.e Sphynx DeFi App, Metamask etc)"
                )}
              </p>
              <p>
                2.{" "}
                {t(
                  "Open Maaly.cards and register to create an account through your wallet browser if you are using a Sphynx DeFi app go to"
                )}
              </p>
              <p>
                3.{" "}
                {t("Verify your registered account with the verification code")}
              </p>
              <p>4. {t("Click on Manage Product")}</p>
              <p>5. {t("Connect your wallet")}</p>
              <p>6. {t("Insert the wallet address you wish to use")}</p>
              <p>7. {t("Click on Approve")}</p>
              <p>
                8.{" "}
                {t(
                  "Set the limit (this will be the maximum you can spend per transaction) and press confirm"
                )}
              </p>
              <p>9. {t("Download Maaly App from iOS or Android")}</p>
              <p>
                10.{" "}
                {t("Log in using the same registration as you did in Step 1")}
              </p>
              <p>11. {t("Click on Manage Product")}</p>
              <p>
                12.{" "}
                {t(
                  "Insert a wallet address (same as the one you input in Step 6)"
                )}
              </p>
              <p>
                13.{" "}
                {t(
                  "Click on activation from the Home Screen of the Maaly app and buy an activation code (skip this step if you have a promo code)"
                )}
              </p>
              <p>
                14.{" "}
                {t(
                  "Once you have received the activation code go to activation from the Home Screen of the Maaly app and scan the product against your device that you wish to use for making payments"
                )}
              </p>
              <p>15. {t("Insert your activation code")}</p>
            </div>
          </Modal>
        )}
        {showWalletModal && (
          <Modal
            title={t(
              "How it works if you already have a Crypto Wallet (Non-custodial)"
            )}
            onDismiss={() => setShowWalletModal(false)}
          >
            <div style={{ lineHeight: "2rem", fontWeight: 400 }}>
              <p>
                1.{" "}
                {t(
                  "Open Maaly.cards and register to create an account through your wallet browser if you are using a Sphynx DeFi app go to"
                )}
              </p>
              <p>
                2.{" "}
                {t("Verify your registered account with the verification code")}
              </p>
              <p>3. {t("Click on Manage Product")}</p>
              <p>4. {t("Connect your wallet")}</p>
              <p>5. {t("Insert the wallet address you wish to use")}</p>
              <p>6. {t("Click on Approve")}</p>
              <p>
                7.{" "}
                {t(
                  "Set the limit (this will be the maximum you can spend per transaction) and press confirm"
                )}
              </p>
              <p>8. {t("Download Maaly App from iOS or Android")}</p>
              <p>
                9.{" "}
                {t("Log in using the same registration as you did in Step 1")}
              </p>
              <p>10. {t("Click on Manage Product")}</p>
              <p>
                11.{" "}
                {t(
                  "Insert a wallet address (same as the one you input in Step 5)"
                )}
              </p>
              <p>
                12.{" "}
                {t(
                  "Click on activation from the Home Screen of the Maaly app and buy an activation code (skip this step if you have a promo code)"
                )}
              </p>
              <p>
                13.{" "}
                {t(
                  "Once you have received the activation code go to activation from the Home Screen of the Maaly app and scan the product against your device that you wish to use for making payments"
                )}
              </p>
              <p>14. {t("Insert your activation code")}</p>
            </div>
          </Modal>
        )}
        <Section>
          <SectionWrapper1
            style={{
              position: "relative",
              paddingBottom: isMobile ? "20px" : "60px",
              textAlign: isMobile ? "center" : "inherit",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SPayText1>Maaly is</SPayText1>
            <br />
            <SPayText2>Possible!</SPayText2>
            <Text
              size={24}
              bold="400"
              color={Colors.black}
              style={{
                lineHeight: "40px",
                paddingTop: "20px",
                fontWeight: "bold",
                margin: "auto",
                maxWidth: "460px",
                zIndex: 2,
              }}
            >
              {t("Experience Crypto Payments Offline")}
            </Text>
            <FlexCenter style={{ paddingTop: "50px", gap: "18px", zIndex: 2 }}>
              <BlackButton onClick={() => window.open("https://maaly.cards")}>
                {t("Get A Card")}
              </BlackButton>
              <PinkBorderButton
                onClick={() => window.open("https://maalyportal.com")}
              >
                {t("Register Business")}
              </PinkBorderButton>
            </FlexCenter>
            <FlexCenter style={{ paddingTop: "50px", gap: "18px" }}>
              <img
                src="/images/mainBack.png"
                alt="background"
                style={{
                  width: "80vw",
                  height: "auto",
                  zIndex: 1,
                  marginTop: "-24px",
                }}
              />
            </FlexCenter>
          </SectionWrapper1>
        </Section>
        <Section
          style={{
            zIndex: 1,
            textAlign: "center",
            paddingTop: "60px",
            paddingBottom: "60px",
            background:
              "linear-gradient(180deg, rgba(248, 248, 255, 0) 0%, #FAF8FF 100%)",
          }}
        >
          <FlexColumn
            style={{
              flex: 1,
              maxWidth: "1200px",
              width: "80%",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              boxShadow: "0px 28px 54px #ee88dd44",
            }}
          >
            <Text size={42} bold="700" color={Colors.black}>
              {t("Accept Crypto Payments")}{" "}
              <span style={{ color: Colors.indigo }}>{t("Anywhere")}</span>
            </Text>
            <FlexBetween
              style={{
                gap: "18px",
                padding: "24px",
                paddingBottom: "80px",
                flexWrap: "wrap",
              }}
            >
              <AcceptCard>
                <IconWrapper style={{ marginTop: "40px" }}>
                  <GlobeIcon width={40} height={40} color="white" />
                </IconWrapper>
                <Text size={24} bold="600" color={Colors.black}>
                  {t("NFC PAYMENT")}
                </Text>
                <Text
                  size={18}
                  bold="400"
                  color={Colors.textGray1}
                  style={{ maxWidth: "420px", marginBottom: "120px" }}
                >
                  {t(
                    "Experience Seamless crypto payments by activating a NFC product to your crypto wallet"
                  )}
                </Text>
              </AcceptCard>
              <AcceptCard>
                <IconWrapper style={{ marginTop: "40px" }}>
                  <MailIcon width={40} height={40} color="white" />
                </IconWrapper>
                <Text size={24} bold="600" color={Colors.black}>
                  {t("QR PAYMENTS")}
                </Text>
                <Text
                  size={18}
                  bold="400"
                  color={Colors.textGray1}
                  style={{ maxWidth: "420px", marginBottom: "120px" }}
                >
                  {t(
                    "Make crypto payments by scanning the QR code for quick and easy payment."
                  )}
                </Text>
              </AcceptCard>
              <AcceptCard>
                <IconWrapper style={{ marginTop: "40px" }}>
                  <UsersIcon width={40} height={40} color="white" />
                </IconWrapper>
                <Text size={24} bold="600" color={Colors.black}>
                  {t("App Download")}
                </Text>
                <Text
                  size={18}
                  bold="400"
                  color={Colors.textGray1}
                  style={{ maxWidth: "420px", marginBottom: "120px" }}
                >
                  {t(
                    "Both User and Merchant App is available on iOS and Android"
                  )}
                </Text>
              </AcceptCard>
            </FlexBetween>
          </FlexColumn>
        </Section>
        <Section
          style={{
            zIndex: 1,
            textAlign: "center",
            paddingTop: "60px",
            paddingBottom: "60px",
            background:
              "linear-gradient(180deg, rgba(248, 248, 255, 0) 0%, #FAF8FF 100%)",
          }}
        >
          <FlexColumn
            style={{
              flex: 1,
              maxWidth: "1200px",
              width: "80%",
              justifyContent: "center",
              alignItems: "center",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
            }}
          >
            <Text size={42} bold="700" color={Colors.black}>
              {t("How it works")}
            </Text>
            <FlexBetween
              style={{
                gap: "18px",
                padding: "24px",
                flexDirection: "column",
                paddingBottom: "80px",
                flexWrap: "wrap",
              }}
            >
              <HowToButton onClick={() => setShowNoWalletModal(true)}>
                {t("Don’t have a crypto a wallet")}
              </HowToButton>
              <HowToButton onClick={() => setShowWalletModal(true)}>
                {t("Already have a crypto wallet")}
              </HowToButton>
            </FlexBetween>
          </FlexColumn>
        </Section>
        {/* <FlexCenter>
          <Section5
            style={{ maxWidth: "1300px", padding: "24px", width: "100%" }}
          >
            <FlexBetween
              style={{
                flexWrap: "wrap",
                flexDirection: screenWidth >= 720 ? "row" : "column",
                justifyContent: screenWidth >= 720 ? "inherit" : "center",
                alignItems: screenWidth >= 720 ? "inherit" : "center",
              }}
            >
              <FlexBetween
                style={{
                  flex: 8,
                  minWidth: "320px",
                  justifyContent: "space-around",
                }}
              >
                <img
                  src="/images/paymentFrame.png"
                  alt="payment-frame"
                  style={{
                    maxWidth: "400px",
                    minWidth: isMobile ? "160px" : "320px",
                    width: isMobile ? "90%" : "max-content",
                    height: isMobile ? "auto" : "max-content",
                  }}
                />
                {!isMobile && (
                  <img
                    src="/images/paymentSpliter.png"
                    alt="payment-frame"
                    style={{ height: "100%", width: "auto" }}
                  />
                )}
              </FlexBetween>
              <FlexColumn
                style={{
                  flex: 7,
                  justifyContent: "flex-start",
                  marginTop: "40px",
                  minWidth: isMobile ? "160px" : "320px",
                  textAlign: isMobile ? "center" : "inherit",
                  width: isMobile ? "90%" : "inherit",
                }}
              >
                <Text
                  size={42}
                  bold="700"
                  color={Colors.black}
                  style={{ maxWidth: "420px" }}
                >
                  Spend cryptocurrency instantly. Fast, easy and secure.
                </Text>
                <Text
                  size={18}
                  bold="400"
                  color={Colors.textGray1}
                  style={{ maxWidth: "420px" }}
                >
                  Download to buy crypto at great rates. Buy, store, swap and
                  spend your favorite crypto all in one secure app. Buy crypto
                  with a credit card or debit card. No hidden fees.
                </Text>
              </FlexColumn>
            </FlexBetween>
          </Section5>
        </FlexCenter> */}
        <FlexCenter>
          <Section5
            style={{
              maxWidth: "1300px",
              padding: "0px",
              width: "90%",
              background: Colors.indigo,
              borderRadius: "24px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <FlexBetween>
              <FlexColumn
                style={{
                  flex: 1,
                  justifyContent: "center",
                  marginTop: "40px",
                  padding: "24px",
                  paddingLeft: isMobile ? "24px" : "160px",
                  textAlign: isMobile ? "center" : "inherit",
                }}
              >
                <Text
                  size={42}
                  bold="700"
                  color={Colors.white}
                  style={{ maxWidth: "360px" }}
                >
                  {t("Gain access")} <br />{" "}
                  {t("to a $1+ trillion cryptomarket")}
                </Text>
                <Text
                  size={18}
                  bold="400"
                  color={Colors.white}
                  style={{ maxWidth: "340px", paddingBottom: "40px" }}
                >
                  {t(
                    "Maaly accepts multiple crypto assets which account for 70% of the global crypto market cap."
                  )}
                </Text>
              </FlexColumn>
              {screenWidth > 680 && (
                <FlexColumn
                  style={{
                    flex: 1,
                    position: "relative",
                    maxHeight: "440px",
                    overflow: "hidden",
                  }}
                >
                  <FlowImage src="/images/tokenGroup.png" alt="payment-frame" />
                  <FlowImage1
                    src="/images/tokenGroup.png"
                    alt="payment-frame"
                  />
                </FlexColumn>
              )}
            </FlexBetween>
          </Section5>
        </FlexCenter>
        <FlexCenter>
          <FlexColumn
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: isMobile ? "60px" : "120px",
            }}
          >
            <Text size={42} bold="700" color={Colors.black}>
              {t("We support all crypto wallets")}
            </Text>
            <FlexBetween
              style={{
                background: "#6133DE33",
                gap: "18px",
                justifyContent: "space-around",
                flexWrap: "wrap",
                padding: "32px",
                width: "calc(100% - 64px)",
                marginTop: "24px",
              }}
            >
              <img
                src="/images/wallets/trust.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/meta.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/4.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/sphynx.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/math.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/coinbase.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
              <img
                src="/images/wallets/bnb.png"
                alt="wallet-icon"
                style={{
                  width: isMobile ? "60px" : "100px",
                  height: isMobile ? "60px" : "100px",
                }}
              />
            </FlexBetween>
          </FlexColumn>
        </FlexCenter>
      </HomePage>
      <BottomBar />
    </>
  );
};

export default Home;

const HomePage = styled.div`
  width: 100%;
  min-height: calc(100vh - 354px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
`;
const LanguageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: -50px;
`;
const VerticalFlow = keyframes`
  0% {
    top: 0%;
  }
  100% {
    top: -100%;
  }
`;
const VerticalFlow1 = keyframes`
  0% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
`;

const FlowImage = styled.img`
  position: absolute;
  height: 100%;
  width: auto;
  animation: ${VerticalFlow} 5s linear infinite;
`;
const FlowImage1 = styled.img`
  position: absolute;
  height: 100%;
  width: auto;
  animation: ${VerticalFlow1} 5s linear infinite;
`;

const SPayText1 = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-family: sans-serif;
  background: linear-gradient(0deg, #171717, #171717),
    linear-gradient(180deg, #525252 13.1%, #000000 27.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 40px;
    margin-left: 0;
    margin-right: 12px;
  }
`;

const SPayText2 = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  width: fit-content;
  font-family: sans-serif;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(92.83deg, #c89dff 3.1%, #4900ff 52.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -24px;
  @media (max-width: 768px) {
    font-size: 40px;
    margin-left: 0;
    margin-right: 12px;
  }
`;
const PinkBorderButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: ${Colors.white};
  border: 2px solid #895ff2;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  box-shadow: #895ff233 0px 0px 20px;
  color: ${Colors.textDark};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const HowToPurpleButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  background: #895ff2;
  border: 2px solid #895ff2;
  font-weight: 600;
  font-size: 20px;
  height: 60px;
  box-shadow: #895ff233 0px 0px 20px;
  color: ${Colors.white};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const HowToButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${Colors.black};
  border: none;
  font-weight: 600;
  height: 50px;
  font-size: 20px;
  color: ${Colors.white};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const BlackButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${Colors.black};
  border: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 40px;
  width: 140px;
  font-size: 14px;
  color: ${Colors.white};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const SectionWrapper1 = styled.div`
  text-align: center;
  max-width: 800px;
  padding: 20px;
`;
const Text = styled.p`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? "0.8" : "1")};
  }
  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size > 60
        ? `${(size / 3) * 2}px`
        : size > 50
        ? `${(size / 5) * 4}px`
        : size > 40
        ? `${(size / 7) * 6}px`
        : size > 30
        ? `${(size / 8) * 7}px`
        : size > 20
        ? `${(size / 9) * 8}px`
        : `${size}px`};
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
`;
const AcceptCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 340px;
  text-align: center;
  padding: 12px;
  gap: 24px;
  min-width: 280px;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 28px 54px #88888844;
`;
const Section5 = styled.div`
  position: relative;
  margin-top: 40px;
  padding: 40px 0px;
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
const IconWrapper = styled.div`
  padding: 10px;
  background: ${Colors.indigo};
  box-shadow: "0px 0px 10px #7000FF44";
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
