export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  bgColor: '#ffffff',
  dark: '#0D0D0D',
  lightDark1: '#191919',
  lightDark2: '#252525',
  lightDark3: '#1E1E1E',
  yellow: '#FFD447',
  green: '#78D64B',
  blue: '#3E74FF',
  indigo: '#7000FF',
  lightIndigo: '#B67CFF',
  textDark: '#041417',
  textGray: '#A8A8A8',
  textGray1: '#616161',
  utilsHover: '#F1F0F2',
  utilsHover1: '#EFEFEF',
  spiro: '#1FCFF1',
  red: '#FF0000',
  socialBgColor: '#BFBFBF',
  purple: '#B880FF',
  oldPurple: '#7000FF'
};
