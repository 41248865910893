import React, { useEffect } from "react"
import { BrowserRouter as Router, Outlet, Route, Routes, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Home from "./views/Home"
import "./App.css"

function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} theme="dark" newestOnTop />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
