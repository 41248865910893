import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "config/i18n/en.json"
import id from "config/i18n/id.json"

i18n
  ?.use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    lng: "id",
    fallbackLng: "en",
    resources: {
      en: en,
      id: id,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
