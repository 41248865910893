import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { save, load } from "redux-localstorage-simple"
import application from "./application/reducer"
import users from "./users/reducer"
import global from "./global/reducer"

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    application,
    users,
    global,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: [] })],
  preloadedState: load({ states: [] }),
})

export const useAppDispatch = () => useDispatch()

export default store
