import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { useDefaultThemeChange } from "./state/application/hooks";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { getLibrary } from "./utils/web3React";
import store from "./state";
import { defaultTheme, darkTheme } from "./Theme";

const ThemeProviderWrapper = (props) => {
  const [currentTheme] = useDefaultThemeChange();
  const themes = [defaultTheme, darkTheme];
  return <ThemeProvider theme={themes[currentTheme]} {...props} />;
};

const Providers = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ThemeProviderWrapper>{children}</ThemeProviderWrapper>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
