import { getAddress } from "@ethersproject/address"

export function isAddress(value) {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }

  return true
}
