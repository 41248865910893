import { createReducer } from '@reduxjs/toolkit'
import {
    toggleMenu,
    toggleTheme,
    setCookie
  } from './actions'

  const initialState = {
    menuToggled: true,
    theme: localStorage.getItem('maalyTheme') ? parseInt(localStorage.getItem('maalyTheme')) : 0,
    cookie: localStorage.getItem('maalyCookie') ? parseInt(localStorage.getItem('maalyCookie')) : null,
  }

  export default createReducer(initialState, (builder) =>
  builder
    .addCase(toggleMenu, (state) => {
      state.menuToggled = !state.menuToggled
    })
    .addCase(toggleTheme, (state) => {
      state.theme = (state.theme + 1) % 2
      localStorage.setItem('maalyTheme', (state.theme % 2).toString())
    })
    .addCase(setCookie, (state, { payload }) => {
      if (payload.cookie) {
        state.cookie = payload.cookie
        localStorage.setItem('maalyCookie', payload.cookie)
      } else {
        state.cookie = null
        localStorage.removeItem('maalyCookie')
      }
    })
)