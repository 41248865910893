import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ethers } from 'ethers'
import getNodeUrl from './getRpcUrl'

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()

export const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001, 32520, 25, 64668, 338] })

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001, 32520, 25, 64668, 338],
  rpc: { 56: rpcUrl, 1: getNodeUrl(1), 137: getNodeUrl(137), 32520: getNodeUrl(32520), 64668: getNodeUrl(64668), 25: getNodeUrl(25), 338: getNodeUrl(338)  },
  qrcode: true,
  bridge: "https://bridge.walletconnect.org",
})

export const coinbaseConnector = new WalletLinkConnector({
  url: 'https://api.thesphynx.co/rpc',
  appName: 'SphynxLabs',
  appLogoUrl: 'https://thesphynx.co/static/media/MainLogo.de9df9c3.svg',
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001, 32520, 64668, 338, 25]
})

export const getLibrary = provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider, account, message) => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message)
    return signature
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}
