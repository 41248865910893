import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import styles from "./modals.module.css";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Modal = ({ onDismiss, children, title }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className={styles.modalRoot} style={{ alignItems: "flex-start" }}>
      <div className={styles.modalContainer} style={{ marginTop: "24px" }}>
        <LogoWrapper>
          <h3>{title}</h3>
        </LogoWrapper>
        {children}
      </div>
      <div
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={onDismiss}
      ></div>
    </div>
  );
};

export default Modal;
